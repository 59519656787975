import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
	"flex items-center gap-1 leading-normal transition-colors duration-200 disabled:cursor-not-allowed",
	{
		variants: {
			variant: {
				primary:
					"bg-primary text-white hover:bg-examine-purple-400 active:bg-examine-purple-800 disabled:bg-[#babbbd]",
				"primary-alt": "",
				secondary:
					"bg-gray-200 text-gray-800 border border-transparent hover:text-primary hover:bg-gray-100 hover:border-gray-200 active:bg-gray-400 active:border-gray-400 disabled:bg-gray-200 disabled:text-gray-500",
				outline:
					"text-primary bg-transparent border border-primary hover:bg-primary hover:text-white active:bg-examine-purple-700 active:border-examine-purple-700 disabled:bg-transparent disabled:text-gray-500 disabled:border-gray-500",
				text: "text-primary bg-transparent hover:bg-gray-100 active:bg-gray-200 active:text-examine-purple-700 disabled:text-gray-500",
				link: "hover:text-primary hover:underline",
				premium: "rounded-full bg-premium font-bold text-primary hover:bg-primary hover:text-white",
				yellow: "bg-yellow-400 text-black hover:bg-yellow-500",
				ghost: "bg-transparent hover:bg-gray-100 active:bg-gray-200",
				destructive:
					"bg-quote-red text-white hover:bg-caution-text active:bg-[#4D0000] disabled:bg-gray-400",
			},
			size: {
				sm: "px-2 py-1 text-sm",
				md: "px-3 py-1.5 text-base",
				lg: "px-4 py-2 sm:text-xl",
			},
			rounded: {
				base: "rounded",
				full: "rounded-full px-7",
			},
		},
		defaultVariants: {
			variant: "primary",
			size: "md",
			rounded: "base",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	Icon?: React.ReactElement;
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, children, variant, size, rounded, Icon, ...props }, ref) => {
		return (
			<button
				className={cn(buttonVariants({ variant, size, rounded }), className)}
				ref={ref}
				{...props}
			>
				{Icon
					? React.cloneElement(Icon, {
							className: size === "sm" ? "w-3 h-3 p-0.5" : "w-6 h-6 p-1.5",
					  })
					: null}
				{children}
			</button>
		);
	}
);
Button.displayName = "Button";

interface ToggleButtonProps extends ButtonProps {
	state: "on" | "off";
	icon?: React.ReactElement;
	isCheck?: boolean;
	iconSize?: number;
	loading?: boolean;
}

const ToggleButton = ({
	children,
	state,
	icon,
	iconSize = 24,
	loading,
	isCheck,
	...props
}: ToggleButtonProps) => {
	return (
		<Button
			variant="ghost"
			className={cn(
				"group rounded-full p-1.5 text-primary group-hover:text-white group-active:text-white",
				loading ? "animate-pulse" : ""
			)}
			data-state={state}
			disabled={loading}
			aria-disabled={loading}
			{...props}
		>
			{
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width={iconSize}
					height={iconSize}
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={cn(
						"group-hover:fill-primary group-hover:stroke-white group-active:fill-primary group-active:stroke-white",
						loading ? "animate-pulse" : "",
						isCheck ? "fill-primary group-hover:fill-examine-purple-400" : ""
					)}
				>
					<circle
						cx="12"
						cy="12"
						r="10"
						className="group-hover:stroke-primary group-active:stroke-primary"
					/>

					{!isCheck ? (
						<>
							<path d="M8 12h8" />
							<path
								d="M12 8v8"
								data-state={state}
								className="opacity-0 data-[state=on]:opacity-100"
							/>
						</>
					) : (
						<path d="M8 12.4L10.4 14.8L15.2 10" className="stroke-white" />
					)}
				</svg>
			}
		</Button>
	);
};

export { Button, buttonVariants, ToggleButton };
